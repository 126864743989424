import React, { FC, useCallback, useState } from "react";
import {
  AspectRatioView,
  Bullets,
  Carousel,
  LazyImage,
  RenderBulletsFunction,
  RenderItemFunction,
  theme,
  useScreenSize,
} from "@lookiero/sty-psp-ui";
import { MediaPerspective, MediaProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { useMediaImage } from "../../../../hooks/useMediaImage";
import { style } from "./ProductVariantSlider.style";

const { space16 } = theme();
interface ProductVariantSlider {
  readonly producVariantMedia: MediaProjection[];
  readonly onChanged?: (active: number) => void;
}
const ProductVariantSlider: FC<ProductVariantSlider> = ({ producVariantMedia, onChanged }) => {
  const cdnImageUrl = useMediaImage();
  const screenSize = useScreenSize();
  const isDesktopScreen = screenSize !== "S";

  const [activeIndex, setActiveIndex] = useState(0);
  const handleOnActiveChanged = useCallback(
    (activeIndex: number) => {
      setActiveIndex(activeIndex);
      onChanged?.(activeIndex);
    },
    [onChanged],
  );

  const renderItem: RenderItemFunction<MediaProjection> = useCallback(
    ({ item, index }) => {
      const isCollageImage = item.perspective === MediaPerspective.COLLAGE;
      const isLastItem = index === producVariantMedia.length - 1;

      return (
        <AspectRatioView
          aspectRatio={4 / 3}
          multiplier={isCollageImage && isLastItem ? 2 : 1}
          style={{
            marginTop: isCollageImage && !isDesktopScreen ? space16 : 0,
            alignSelf: isCollageImage ? (isLastItem ? "flex-end" : "flex-start") : undefined,
          }}
        >
          <LazyImage
            hiResSrc={cdnImageUrl({ url: item.url, width: 600 })}
            resizeMode="stretch"
            src={cdnImageUrl({ url: item.url, width: 600, dpi: 1 })}
            style={{ view: [style.image, isDesktopScreen ? style.largeImage : null] }}
            testID="product-variant-image"
          />
        </AspectRatioView>
      );
    },
    [cdnImageUrl, isDesktopScreen, producVariantMedia.length],
  );

  const renderBullets: RenderBulletsFunction = useCallback(
    ({ activeIndex, count, onChange }) => <Bullets activeIndex={activeIndex} count={count} onChange={onChange} />,
    [],
  );

  return (
    <Carousel
      activeIndex={activeIndex}
      bullets={renderBullets}
      data={producVariantMedia}
      onActiveIndexChanged={handleOnActiveChanged}
    >
      {renderItem}
    </Carousel>
  );
};

export { ProductVariantSlider };
