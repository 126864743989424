import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { fontInputPaddingLeft, space2, space4 } = theme();

const INPUT_HEIGHT = 96;

const style = StyleSheet.create({
  input: {
    paddingTop: space4,
    ...Platform.select({
      android: {
        paddingTop: space2,
        height: "auto",
      },
    }),
  },
  inputContainer: {
    height: INPUT_HEIGHT,
    ...Platform.select({
      android: {
        alignItems: "flex-start",
        paddingLeft: fontInputPaddingLeft,
      },
    }),
  },
  inputField: {
    height: INPUT_HEIGHT,
  },
});

export { style };
