import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { CheckoutFeedbackProjection } from "../../../../../../projection/checkoutFeedback/checkoutFeedback";

interface OnChangeCheckoutQuestionFeedbackFunctionArgs {
  readonly checkoutQuestionId: string;
  readonly checkoutQuestionFeedback: string | undefined; // uuid | free-text
  readonly hasFeedback?: boolean;
}

interface OnChangeCheckoutQuestionFeedbackFunction {
  (args: OnChangeCheckoutQuestionFeedbackFunctionArgs): void;
}

interface CheckoutQuestionFeedbackContextShape {
  readonly feedback: CheckoutFeedbackProjection;
  readonly onChange: OnChangeCheckoutQuestionFeedbackFunction;
}

const CheckoutQuestionFeedbackContext = createContext<CheckoutQuestionFeedbackContextShape>(
  null as unknown as CheckoutQuestionFeedbackContextShape,
);

interface CheckoutQuestionFeedbackContextProviderProps {
  readonly onChanged?: OnChangeCheckoutQuestionFeedbackFunction;
  readonly children: ReactNode;
}

const CheckoutQuestionFeedbackProvider: FC<CheckoutQuestionFeedbackContextProviderProps> = ({
  onChanged,
  children,
}) => {
  const [contextFeedback, setContextFeedback] = useState<CheckoutFeedbackProjection>({});
  const onChange = useCallback<OnChangeCheckoutQuestionFeedbackFunction>(
    ({ checkoutQuestionId, checkoutQuestionFeedback }) => {
      setContextFeedback((feedback) =>
        checkoutQuestionFeedback
          ? { ...feedback, [checkoutQuestionId]: checkoutQuestionFeedback }
          : Object.entries(feedback).reduce(
              (acc, [id, feedback]) => (id !== checkoutQuestionId ? { ...acc, [id]: feedback } : acc),
              {},
            ),
      );
      onChanged?.({
        checkoutQuestionId,
        checkoutQuestionFeedback,
        hasFeedback: Boolean(contextFeedback[checkoutQuestionId]),
      });
    },
    [contextFeedback, onChanged],
  );

  const value = useMemo(
    () => ({
      feedback: contextFeedback,
      onChange,
    }),
    [contextFeedback, onChange],
  );

  return <CheckoutQuestionFeedbackContext.Provider value={value}>{children}</CheckoutQuestionFeedbackContext.Provider>;
};

interface UseCheckoutQuestionFeedbackForIdFunctionArgs {
  readonly id: string;
}

interface UseCheckoutQuestionFeedbackForIdFunction {
  (args: UseCheckoutQuestionFeedbackForIdFunctionArgs): {
    readonly feedback: string | undefined;
    readonly onChange: OnChangeCheckoutQuestionFeedbackFunction;
  };
}

const useCheckoutQuestionFeedbackForId: UseCheckoutQuestionFeedbackForIdFunction = ({ id }) => {
  const checkoutQuestionFeedbackContext = useContext<CheckoutQuestionFeedbackContextShape>(
    CheckoutQuestionFeedbackContext,
  );

  invariant(
    checkoutQuestionFeedbackContext,
    "Your are trying to use the useCheckoutQuestionFeedbackForId hook without wrapping your app with the <CheckoutQuestionFeedbackProvider>.",
  );

  const { feedback, onChange } = checkoutQuestionFeedbackContext;

  return { feedback: feedback[id], onChange };
};

interface UseCheckoutQuestionFeedbackFunction {
  (): CheckoutFeedbackProjection;
}

const useCheckoutQuestionFeedback: UseCheckoutQuestionFeedbackFunction = () => {
  const checkoutQuestionFeedbackContext = useContext<CheckoutQuestionFeedbackContextShape>(
    CheckoutQuestionFeedbackContext,
  );

  invariant(
    checkoutQuestionFeedbackContext,
    "Your are trying to use the useCheckoutQuestionFeedback hook without wrapping your app with the <CheckoutQuestionFeedbackProvider>.",
  );

  const { feedback } = checkoutQuestionFeedbackContext;

  return feedback;
};

export { useCheckoutQuestionFeedbackForId, useCheckoutQuestionFeedback, CheckoutQuestionFeedbackProvider };
